import { Injectable } from '@angular/core';
import {UrlService} from '../Url/url.service'

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  
  constructor(private url:UrlService,private http:HttpClient) { }
  serverUrl=`${this.url.url}/media`


  async addMedia(formData:any){
    return this.http.post(`${this.serverUrl}/`,formData).toPromise()
  }

  async overwriteAndUploadMedia(formData:any, filename:any){
    return this.http.post(`${this.serverUrl}/overwriteAndUpload?filename=${filename}`,formData).toPromise()
  }

  async getAllMedia(){
    return this.http.get(`${this.serverUrl}/getAllMedia`).toPromise()
  }

  async getAllActiveMedia(){
    return this.http.get(`${this.serverUrl}/getAllActiveMedia`).toPromise()
    
  }

  async toggleMedia(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/toggleMedia/${id}`,obj).toPromise()
  }
  async removeMedia(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/removeMedia/${id}`,obj).toPromise()
  }


}
