import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../services/store/store.service';
import { UrlService } from '../services/Url/url.service';

@Component({
  selector: 'app-admin-store-order-duplicate-invoice',
  templateUrl: './admin-store-order-duplicate-invoice.component.html',
  styleUrls: ['./admin-store-order-duplicate-invoice.component.scss']
})
export class AdminStoreOrderDuplicateInvoiceComponent implements OnInit {
  today = new Date();
  id: any;
  orderObj: any;
  storeObj: any;
  totalAmountBefore: number = 0;
  CGST: number = 0;
  SGST: number = 0;
  IGST: number = 0;
  taxAmount: number = 0;
  roundOff: any = 0;
  Total: number = 0;
  constructor(private route: ActivatedRoute, private url: UrlService, private http: HttpClient, private Store: StoreService) {
    this.id = this.route.snapshot.params['id']
  }

  ngOnInit(): void {
    this.getOrder();
  }

  async getStore(id) {
    let res: any = await this.Store.getStoreById(id)
    console.log(res.data)
    if (res.data)
      this.storeObj = res.data
  }

  async getOrder() {
    let res: any = await this.http.get(`${this.url.url}/adminStoreOrder/getById/${this.id}`).toPromise()
    console.log(res.data)
    console.log(res.data.productArr)
    if (res.data) {
      this.orderObj = res.data
      this.orderObj.productArr = this.orderObj.productArr.map(obj => {
        let tempobj = { ...obj, size: obj.variantObj?.variantList?.find(el => el.name == 'Size')?.variantName }
        return tempobj
      })
      this.totalAmountBefore = this.orderObj.productArr.reduce((acc, obj) => acc + (obj.variantObj?.wholesalePrice    * obj.qty), 0)
      this.CGST = this.orderObj.productArr.reduce((acc, obj) => acc + (obj.variantObj?.wholesalePrice * obj.qty * 0.025), 0)
      this.SGST = this.orderObj.productArr.reduce((acc, obj) => acc + (obj.variantObj?.wholesalePrice * obj.qty * 0.025), 0)
      this.taxAmount = this.CGST + this.IGST + this.SGST;
      let finalAmount = this.totalAmountBefore + this.taxAmount
      this.Total = Math.ceil(finalAmount) 
      this.roundOff = (this.Total - finalAmount).toFixed(2)
      this.getStore(this.orderObj.storeId)
    }
  }




}
