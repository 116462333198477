import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';
@Injectable({
  providedIn: 'root'
})
export class AttributeService {
  constructor(private url:UrlService,private http:HttpClient) { }

  serverUrl=`${this.url.url}/Attribute`

  async addAttribute(formData){
    return this.http.post(`${this.serverUrl}`,formData).toPromise()
  }
  async getAllAttribute(){
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  

  async toggleAttribute(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/toggleAttribute/${id}`,obj).toPromise()
  }
  async removeAttribute(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/removeAttribute/${id}`,obj).toPromise()
  }
}
