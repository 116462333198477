import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/brand`

  addBrand(formData) {
    return this.http.post(`${this.serverUrl}/`, formData).toPromise()
  }

  getAllBrands() {
    return this.http.get(`${this.serverUrl}/getAll`).toPromise()
  }
  changeBrandPosition(formData) {
    return this.http.patch(`${this.serverUrl}/brandPositionAdjust`, formData).toPromise()
  }

  updateBrand(id, formData) {
    return this.http.patch(`${this.serverUrl}/updateById/${id}`, formData).toPromise()
  }

  deleteById(id) {
    return this.http.delete(`${this.serverUrl}/deleteById/${id}`).toPromise()
  }

}
