import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private url:UrlService,private http:HttpClient) { }
  serverUrl=`${this.url.url}/subscription`

  async addSubscription(formData){
    return this.http.post(`${this.serverUrl}`,formData).toPromise()
  }
  async getAllSubscriptions(){
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  

  async toggleSubscriptions(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/setActiveVal/${id}`,obj).toPromise()
  }
  async removeSubscription(id:any){
    return this.http.delete(`${this.serverUrl}/deleteById/${id}`).toPromise()
  }


}
