import { UrlService } from 'src/app/services/Url/url.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/city`

  async createCity(formData) {
    return this.http.post(`${this.serverUrl}/`, formData).toPromise()
  }
  async getAllCities() {
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }


  async getAllCitiesByStateId(id) {
    return this.http.get(`${this.serverUrl}/getByStateId/${id}`).toPromise()
  }



  async toggleCityStatus(id: any, obj: any) {
    return this.http.patch(`${this.serverUrl}/toggleCity/${id}`, obj).toPromise()
  }
  async updateCity(id: any, obj: any) {
    return this.http.patch(`${this.serverUrl}/setActiveVal/${id}`, obj).toPromise()
  }
  async removeCity(id: any) {
    return this.http.patch(`${this.serverUrl}/deleteById/${id}`, {}).toPromise()
  }


}
