import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class BuyonegetoneService {

  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/BuyOneGetOne`

  addBuyOneGetOne(obj: any) {
    return this.http.post(`${this.serverUrl}`, obj).toPromise()
  }


  getAllBuyOneGetOne() {
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  // setCouponActive(id: string, obj: any) {
  //   return this.http.patch(`${this.serverUrl}/setActive/${id}`, obj).toPromise()
  // }

}
