import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProductService } from "../services/Product/product.service";
import { StoreService } from "../services/store/store.service";
import { UrlService } from "../services/Url/url.service";

@Component({
  selector: "app-admin-store-order-invoice",
  templateUrl: "./admin-store-order-invoice.component.html",
  styleUrls: ["./admin-store-order-invoice.component.scss"],
})
export class AdminStoreOrderInvoiceComponent implements OnInit {
  today = new Date();
  id: any;
  orderObj: any;
  storeObj: any;
  sameState: boolean = true;
  totalAmountBefore: number = 0;
  CGST: number = 0;
  SGST: number = 0;
  IGST: number = 0;
  taxAmount: number = 0;
  roundOff: any = 0;
  Total: number = 0;
  original: any;
  totalQuantity: number = 0;
  hsnArr: any = [];

  constructor(private route: ActivatedRoute, private url: UrlService, private http: HttpClient, private Store: StoreService, public Product: ProductService) {
    this.id = this.route.snapshot.params["id"];
    let tempVal = this.route.snapshot.params["original"];
    if (tempVal == 1) {
      this.original = "Original";
    } else if (tempVal == 2) {
      this.original = "Duplicate";
    } else {
      this.original = "Triplicate";
    }
  }

  ngOnInit(): void {
    this.getOrder();
  }

  async getStore(id) {
    let res: any = await this.Store.getStoreById(id);
    console.log(res.data);
    if (res.data) this.storeObj = res.data;
  }

  async getOrder() {
    let res: any = await this.http.get(`${this.url.url}/adminStoreOrder/getById/${this.id}`).toPromise();
    console.log(res.data, "DAA");
    console.log(res.data.productArr);
    if (res.data) {
      this.orderObj = res.data;
      this.orderObj.productArr = this.orderObj.productArr.map((obj) => {
        let tempobj = { ...obj, size: obj.variantObj?.variantList?.find((el) => el.name == "Size")?.variantName };
        return tempobj;
      });
      await this.getStore(this.orderObj.storeId);
      if (this.storeObj?.storeName?.toUpperCase()?.includes("DELHI")) {
        this.sameState = true;
      }
      let finalHsnArr = [];
      this.orderObj.productArr.forEach((el, i) => {
        if (i == 200) {
          console.log(el);
          console.log(el.variantObj?.wholesalePrice);
          console.log(this.Product.getCGstForProduct("FOOTWEAR", el.variantObj?.wholesalePrice));
        }
        if (finalHsnArr.some((ele) => ele.hsnCode == el.productObj.hsnCode)) {
          let hsnIndex = finalHsnArr.findIndex((ele) => ele.hsnCode == el.productObj.hsnCode);
          finalHsnArr[hsnIndex].qty += el.qty;
          finalHsnArr[hsnIndex].taxableValue +=
            Math.round(el?.wholesalePrice * el?.cgstPercentage * el.qty) +
            Math.round(el?.wholesalePrice * el?.sgstPercentage * el.qty) +
            Math.round(el?.wholesalePrice * el?.igstPercentage * el.qty) +
            Math.round(el?.wholesalePrice * el.qty);
        } else {
          let obj = {
            hsnCode: el.productObj.hsnCode,
            qty: el.qty,
            taxableValue:
              Math.round(el?.wholesalePrice * el?.cgstPercentage * el.qty) +
              Math.round(el?.wholesalePrice * el?.sgstPercentage * el.qty) +
              Math.round(el?.wholesalePrice * el?.igstPercentage * el.qty) +
              Math.round(el?.wholesalePrice + el.qty),
          };
          finalHsnArr.push(obj);
        }
      });
      this.hsnArr = finalHsnArr;
      this.totalAmountBefore = this.orderObj.productArr.reduce((acc, obj) => acc + obj?.wholesalePrice * obj.qty, 0);
      this.CGST = Math.round(this.orderObj.productArr.reduce((acc, obj) => acc + obj?.wholesalePrice * obj.cgstPercentage * obj.qty, 0));
      this.SGST = Math.round(this.orderObj.productArr.reduce((acc, obj) => acc + obj?.wholesalePrice * obj.sgstPercentage * obj.qty, 0));
      this.IGST = Math.round(this.orderObj.productArr.reduce((acc, obj) => acc + obj?.wholesalePrice * obj.igstPercentage * obj.qty, 0));
      this.taxAmount = Math.round(this.CGST + this.IGST + this.SGST);
      let finalAmount = this.totalAmountBefore + this.taxAmount;
      this.Total = Math.ceil(finalAmount);
      this.totalQuantity = this.orderObj.productArr.reduce((acc, obj) => acc + obj.qty, 0);
      this.roundOff = this.Total - finalAmount;
    }
  }
}
