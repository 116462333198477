<div class="container">
  <div id="container" class="row d-flex justify-content-center">

    <div class="col-12 invoice" *ngFor="let el of barcodeContentArr; let i as index" style="padding: 0px 50px;">
      <div
        style="width: 100%;display: flex;align-self: center;justify-content: center;align-items: center;flex-direction: column; padding-top: 20px;">
        <ngx-barcode [bc-value]="el.stockNo" [bc-width]="6" [bc-font-size]='50' [bc-height]='50'
          [bc-display-value]="true">
        </ngx-barcode>
        <div
          style="display: flex;justify-content: space-between;flex-direction: row;width: 90%; position: relative;top: -15px;">
          <p
            style="margin-left: 10px;font-weight: bold;font-size: 30px;padding: 0px; display: flex;justify-content: space-between;  width: 100%; flex-direction: row;">
            <span style="text-align: left;">Art No: {{el.articleNo}}</span> 
            <span style="text-align: right;">Size: {{el.size}}</span>
          </p>

        </div>
        <div style="width: 90%; position: relative;top: -20px;">
          <p
            style="position: relative;top: -20px;margin-left: 10px;font-weight: bold;font-size: 30px;padding: 0px; display: flex; justify-content: space-between;flex-direction: row;">
            <span>
              Style: {{el.style}}
            </span>
            <span>
              Qty: 1 Pair
            </span>
          </p>
          <p style="position: relative;top: -40px;margin-left: 10px;font-weight: bold;font-size: 30px;padding: 0px;">
            Color: {{el.color}}</p>
          <div
            style="display: flex;justify-content: space-between;flex-direction: row;width: 100%; position: relative;top: -45px;">
            <p style="margin-left: 10px;font-weight: bold;font-size: 34px;padding: 0px; text-align: left;"> MRP:
              <s>{{el.price}}</s>
              <br>
              (Incl of all taxes)
            </p>
            <p style="position: relative;left: -55px;font-weight: bold;font-size: 34px;padding: 0px;">Disc:
              {{el.discountAmount}}%</p>
            <p style="position: relative;left: -45px;font-weight: bold;font-size: 34px;padding: 0px;">R. Value:
              {{el.realizedValue|number}}
            <br>
            MFD: {{el.mfdMonth}}
            </p>
          </div>
          <div
            style="display: flex;justify-content: space-between;flex-direction: row;width: 100%; position: relative;top: -45px; font-size: 34px; height: 72px;">
            <p style="position: relative;right: -445px;top: -80px;font-weight: bold;font-size: 34px;padding: 0px;"></p>
          </div>
        </div>
        <div style="position: relative;top: -150px;left:-30px">

          <p style="font-weight: bold;margin-left: 10px;font-size: 25px;margin: 0px;text-align: center;padding: 0px;">
            Marketed By : AHEAD
            RETAIL PVT LTD</p>
          <p style="font-weight: bold;margin-left: 30px;font-size: 25px;text-align: center;padding: 0px 10px;">RZ-3A,
            SITAPURI, NEW DELHI, New Delhi,
            Delhi, 110045</p>
          <p
            style="font-weight: bold;font-size: 25px;text-align: center;margin: 0px;padding: 0px;position: relative;top: -20px;">
            Email Id :
            customersupport@jootelelo.com</p>
        </div>
      </div>
    </div>
  </div>
</div>