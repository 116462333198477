import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class BannerService {


  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/banner`

  async addBanner(formData) {
    return this.http.post(`${this.serverUrl}`, formData).toPromise()
  }

  async getAllBanner() {
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  async getBannerById(id) {
    return this.http.get(`${this.serverUrl}/getById/${id}`).toPromise()
  }

  async updateBannerById(id:string, obj) {
    return this.http.patch(`${this.serverUrl}/updateById/${id}`, obj).toPromise()
  }

  async deleteById(id) {
    return this.http.delete(`${this.serverUrl}/deleteById/${id}`).toPromise()
  }
}
