import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global/global.service';
import { UserService } from 'src/app/services/User/user.service';
import { AuthService } from '../../../../services/firebase/auth.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  constructor(public authService: AuthService,
    public Global : GlobalService,
    private User : UserService,
    private router:Router
    ) { }

  ngOnInit() {
    this.getUserObj()
  }

  async getUserObj(){
    try {
      if(!this.Global.userObj?._id){
        if(this.User.getJwt()){
          let res:any = await this.User.getCurrentUserDetails()
          if(res.data)
          this.Global.userObj = res.data
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  logout () {
    console.log("LOGGIN OUT")
    this.User.removeJwt();
    this.router.navigateByUrl('/auth/login')
  }
}
