import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class ImageUtilsService {

  constructor(private Url: UrlService) { }

  generateImageUrl = (fileName: string | any) => {
    if (fileName && typeof fileName == 'string') {
      if (fileName?.startsWith('https://') || fileName?.startsWith('http://')){
        return `${fileName}`
      }
      else
        return `${this.Url.url}/uploads/${fileName}`
    }
    else {
      return ""
    }
  }
}
