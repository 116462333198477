import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProductService } from "../../../services/Product/product.service";
import { StoreService } from "../../../services/store/store.service";
import { UrlService } from "../../../services/Url/url.service";
@Component({
  selector: "app-admin-vendor-return-order-invoice",
  templateUrl: "./admin-vendor-return-order-invoice.component.html",
  styleUrls: ["./admin-vendor-return-order-invoice.component.scss"],
})
export class AdminVendorReturnOrderInvoiceComponent implements OnInit {
  today: any;
  id: any;
  orderObj: any;
  storeObj: any;
  sameState: boolean = true;
  totalAmountBefore: number = 0;
  CGST: number = 0;
  SGST: number = 0;
  IGST: number = 0;
  taxAmount: number = 0;
  roundOff: any = 0;
  Total: number = 0;
  original: any;
  totalQuantity: number = 0;
  hsnArr: any = [];

  constructor(private route: ActivatedRoute, private url: UrlService, private http: HttpClient, private Store: StoreService, public Product: ProductService) {
    this.id = this.route.snapshot.params["id"];
    this.today = new Date();

    let tempVal = this.route.snapshot.params["original"];
    if (tempVal == 1) {
      this.original = "Original";
    } else if (tempVal == 2) {
      this.original = "Duplicate";
    } else {
      this.original = "Triplicate";
    }
  }

  ngOnInit(): void {
    this.getOrder();
  }

  // async getStore(id) {
  //   let res: any = await this.Store.getStoreById(id)
  //   console.log(res.data)
  //   if (res.data)
  //     this.storeObj = res.data
  // }

  async getOrder() {
    let res: any = await this.http.get(`${this.url.url}/AdminVendorReturnOrder/getById/${this.id}`).toPromise();
    console.log(res.data);
    console.log(res.data.productArr);
    if (res.data) {
      this.orderObj = res.data;
      this.orderObj.productArr = this.orderObj.productArr.map((obj) => {
        let tempobj = { ...obj, size: obj.variantObj?.variantList?.find((el) => el.name == "Size")?.variantName };
        return tempobj;
      });
      // await this.getStore(this.orderObj.storeId)
      if (this.storeObj?.storeName?.toUpperCase()?.includes("DELHI")) {
        this.sameState = true;
      }
      let finalHsnArr = [];
      this.orderObj.productArr.forEach((el, i) => {
        if (i == 200) {
          console.log(el);
          console.log(el.variantObj?.wholesalePrice);
          // console.log(this.Product.getCGstForProduct('FOOTWEAR', el.variantObj?.wholesalePrice))
        }
        if (finalHsnArr.some((ele) => ele.hsnCode == el.productObj.hsnCode)) {
          let hsnIndex = finalHsnArr.findIndex((ele) => ele.hsnCode == el.productObj.hsnCode);
          finalHsnArr[hsnIndex].qty += el.qty;
          finalHsnArr[hsnIndex].taxableValue += Math.round(el.adminPurchasedPrice * el.qty * 100) / 100;
        } else {
          let obj = {
            hsnCode: el.productObj.hsnCode,
            qty: el.qty,
            taxableValue:
              Math.round(el.variantObj?.wholesalePrice * this.Product.getCGstForProduct(el.productObj?.department, el.variantObj?.wholesalePrice, this.sameState) * el.qty) +
              Math.round(el.variantObj?.wholesalePrice * this.Product.getSGstForProduct(el.productObj?.department, el.variantObj?.wholesalePrice, this.sameState) * el.qty) +
              Math.round(el.variantObj?.wholesalePrice * this.Product.getIGstForProduct(el.productObj?.department, el.variantObj?.wholesalePrice, this.sameState) * el.qty) +
              Math.round(el.variantObj?.wholesalePrice + el.qty),
          };
          finalHsnArr.push(obj);
        }
      });
      this.hsnArr = finalHsnArr;
      this.totalAmountBefore = this.orderObj.productArr.reduce((acc, obj) => acc + obj.adminPurchasedPrice * obj.qty, 0);
      this.CGST = this.orderObj.productArr.reduce((acc, obj) => acc + obj.variantObj?.wholesalePrice * obj.cgstPercentage * obj.qty, 0);
      this.SGST = this.orderObj.productArr.reduce((acc, obj) => acc + obj.variantObj?.wholesalePrice * obj.sgstPercentage * obj.qty, 0);
      this.IGST = this.orderObj.productArr.reduce((acc, obj) => acc + obj.variantObj?.wholesalePrice * obj.igstPercentage * obj.qty, 0);
      this.taxAmount = this.CGST + this.IGST + this.SGST;
      let finalAmount = this.totalAmountBefore + this.taxAmount;
      this.Total = Math.round(finalAmount);
      this.totalQuantity = this.orderObj.productArr.reduce((acc, obj) => acc + obj.qty, 0);
      this.roundOff = this.Total - finalAmount;
    }
  }
}
