import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from 'src/app/services/Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class StoreStockService {
  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/productStock`

  async addStoreStocks(formData) {
    return this.http.post(`${this.serverUrl}/`, formData).toPromise()
  }
  async getStoreStocks() {
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }
  async getStoreStocksByProductId(storeId, productId) {
    return this.http.get(`${this.serverUrl}/getByStoreAndProductId/${storeId}/${productId}`).toPromise()
  }



  async removeStores(id: any) {
    return this.http.patch(`${this.serverUrl}/deleteById/${id}`, {}).toPromise()
  }


}
