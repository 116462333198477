import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlService } from '../Url/url.service';
@Injectable({
  providedIn: 'root'
})
export class AdminStoreOrderReturnService {

  constructor(private url:UrlService,private http:HttpClient) { }

  serverUrl=`${this.url.url}/adminStoreReturnOrder`

  async getOrderById(id: any) {
    return this.http.get(`${this.serverUrl}/getOrderById/${id}`).toPromise()
  }

  async getAllOrder(id) {
    return this.http.get(`${this.serverUrl}/getAllByStoreId/${id}`).toPromise()
  }


  async deliverReturnOrder(id: any) {
    // let tokenObj:any=await this.user.decodeJwt()
    return this.http.patch(`${this.serverUrl}/deliverReturnOrder/${id}`, {}).toPromise()
  }

}
