import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';
@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/store`

  async addStore(formData) {
    return this.http.post(`${this.serverUrl}/`, formData).toPromise()
  }
  
  async getAllStores() {
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  async getStoreById(id) {
    return this.http.get(`${this.serverUrl}/getById/${id}`).toPromise()
  }




  async removeStores(id: any) {
    return this.http.patch(`${this.serverUrl}/deleteById/${id}`, {}).toPromise()
  }


async updateStores(id: any,obj) {
    return this.http.patch(`${this.serverUrl}/updateById/${id}`, obj).toPromise()
  }
  async updateStoreImage(id: any,obj) {
    return this.http.patch(`${this.serverUrl}/updateStoreImage/${id}`, obj).toPromise()
  }

  

  

}