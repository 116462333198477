import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';
import { UserService } from '../User/user.service';

@Injectable({
  providedIn: 'root'
})


export class StoreOrderService {

  constructor(private url: UrlService, private http: HttpClient, private user: UserService) { }
  serverUrl = `${this.url.url}/StoreOrder`


  async getOrderByStoreId(id) {
    return this.http.get(`${this.serverUrl}/getByStoreId/${id}`).toPromise()
  }

  async cancelStoreOrderById(id, obj) {
    const token = this.user.getJwt()
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
    }
    return this.http.patch(`${this.serverUrl}/cancelOrderById/${id}`, obj, httpOptions).toPromise()
  }


  async updateStoreOrder(obj) {
    const token = this.user.getJwt()
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
    }
    return this.http.patch(`${this.serverUrl}/updateOrderByInvoiceNumber`, obj, httpOptions).toPromise()
  }




}




