import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(private url:UrlService,private http:HttpClient) { }
  serverUrl=`${this.url.url}/tag`

  async addTag(formData){
    return this.http.post(`${this.serverUrl}`,formData).toPromise()
  }
  async getAllTags(){
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  

  async toggleTag(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/toggleTag/${id}`,obj).toPromise()
  }
  async removeTag(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/removeTag/${id}`,obj).toPromise()
  }


}
