<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card mt-4">
                <div class="card-header d-flex justify-content-between">
                    <h5>{{variantObj?.name}} Update Hex code</h5>
                </div>
                <div class="col-2">

                    <div class="card-body custom-datatable">
                        <form class="custom-datepicker custom-datepicker-dropdown">
                            <div class="form-group" *ngFor="let el of  variantObj?.variantArr">
                                <label>{{el.name}}</label>
                                <div class="input-group">
                                    <input id="file" type="color" [value]="el.colorHex" (change)="handleVariantColorSelection(el._id,$event)" class="form-control"  />
                                </div>
                            </div>
                            
                            
                            
                        </form>
                        
                        <button type="button" (click)="handleSubmit()" class="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>