import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/users`



  async login(formData) {
    return this.http.post(`${this.serverUrl}/login`, formData).toPromise()
  }

  async register(formData) {
    return this.http.post(`${this.serverUrl}/register`, formData).toPromise()
  }


  setJwt(token) {
    localStorage.setItem("Auth_Token", token)
    return true
  }
  removeJwt() {
    localStorage.removeItem("Auth_Token")
    return true
  }

  getJwt() {
    return localStorage.getItem("Auth_Token")
  }


  decodeJwt() {
    let token = this.getJwt();
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }



  registerPosStores(formData) {
    return this.http.post(`${this.serverUrl}/registerPosStores`, formData).toPromise()
  }

  getAllSupplierEatable() {
    console.log(this.serverUrl)
    return this.http.get(`${this.serverUrl}/getAllSupplierEatable`).toPromise()
  }

  getUserById(id) {
    return this.http.get(`${this.serverUrl}/getById/${id}`).toPromise()
  }

  getCurrentUserDetails() {
    let decodedObj: any = this.decodeJwt()
    if (decodedObj?.userId) {
      return this.getUserById(decodedObj?.userId)
    }
    return null
  }

  deleteUser(id: any) {
    return this.http.delete(`${this.serverUrl}/deleteById/${id}`).toPromise()
  }

  updateUser(id: any, userObj: any) {
    return this.http.patch(`${this.serverUrl}/updateById/${id}`, userObj).toPromise()
  }

  updateUserAddress(id: any, addressObj: any) {
    return this.http.patch(`${this.serverUrl}/updateAddressById/${id}`, addressObj).toPromise()
  }
  async addEmployee(formData) {
    return this.http.post(`${this.serverUrl}/createStoreEmployees`, formData).toPromise()
  }
  async getEmployeesByStoreId(id) {
    return this.http.get(`${this.serverUrl}/getEmployeesByStoreId/${id}`).toPromise()
  }

}
