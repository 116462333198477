<div class="container">
    <div class="row border">
        <div class="col">

            <div style="border-bottom: solid 1px black;">
                <div class="col-12 text-center">
                    <b>JOOTE LELO PRIVATE LIMITED</b>
                </div>
                <div class="col-12 text-center">
                    202-2099/38, S/F Sewak Chamber Naiwala, Karol Bagh
                </div>
                <div class="col-12 text-center">
                    State : Delhi Pin : 110005
                </div>
                <div class="col-12 text-center">
                    E-mail ottloretails@gmail.com Phone
                </div>

                <div class="col-12 text-center">
                    GSTIN : 07AADCO3085F1ZI
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;"
                style="display: flex;flex-direction: row;justify-content: space-between;border-bottom: 1px solid black;">
                <div>
                </div>
                <div class="text-center" style="font-size: 30px;font-weight: bold;">
                    Tax Invoice
                </div>
                <div>
                    Triplicate
                </div>

            </div>

            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-6">Reverse Charge</div>
                        <div class="col-6">: No</div>
                    </div>
                    <div class="row">
                        <div class="col-6">Invoice Number </div>
                        <div class="col-6">OSR/21-22/51</div>
                    </div>
                    <div class="row">
                        <div class="col-6">Invoice Date </div>
                        <div class="col-6">{{today|date:"short"}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">PO Details</div>
                        <div class="col-6"></div>
                    </div>
                </div>
                <div class="col-6" style="border-left: 1px solid black;">
                    <div class="row">
                        <div class="col-6">Transportation mode</div>
                        <div class="col-6"></div>
                    </div>
                    <div class="row">
                        <div class="col-6">Vehicle No </div>
                        <div class="col-6"></div>
                    </div>
                    <div class="row">
                        <div class="col-6">Date of Supply</div>
                        <div class="col-6">{{ orderObj?.createdAt | date :'short'}}</div>
                    </div>
                    <!-- <div class="row">
                    <div class="col-6">Place of Supply</div>
                    <div class="col-6">Delhi [ 07 ]</div>
                </div> -->
                    <!-- <div class="row">
                    <div class="col-6">Eletronic Ref. No.</div>
                    <div class="col-6"></div>
                </div> -->
                </div>
            </div>



            <div class="row">
                <div class="col-6">
                    <div class="row" style="margin: 0px;padding: 0px;">
                        <div class="col-12" style="background-color: #dde087;">
                            Details of Receiver / Billed to:
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">Name</div>
                        <div class="col-9">{{storeObj?.name}}</div>
                    </div>
                    <div class="row">
                        <div class="col-3">Address </div>
                        <div class="col-9">{{storeObj?.areaName}}, {{storeObj?.cityName}}</div>
                    </div>
                    <div class="row">
                        <div class="col-3">GSTIN </div>
                        <div class="col-9">07AECFS7440G1Z0</div>
                    </div>
                    <div class="row">
                        <div class="col-3">State</div>
                        <div class="col-9">{{storeObj?.stateName}}</div>
                    </div>

                </div>
                <div class="col-6" style="border-left: 1px solid black;">
                    <div class="row" style="margin: 0px;padding: 0px;">
                        <div class="col-12" style="background-color: #dde087;">
                            Details of Consignee / Shipped to:
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">Name</div>
                        <div class="col-9">{{storeObj?.name}}</div>
                    </div>
                    <div class="row">
                        <div class="col-3">Address </div>
                        <div class="col-9">{{storeObj?.areaName}}, {{storeObj?.cityName}}</div>
                    </div>
                    <div class="row">
                        <div class="col-3">GSTIN </div>
                        <div class="col-9">07AECFS7440G1Z0</div>
                    </div>
                    <div class="row">
                        <div class="col-3">State</div>
                        <div class="col-9">{{storeObj?.stateName}}</div>
                    </div>
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col" rowspan="2">#</th>
                        <th scope="col" rowspan="2">Item Description</th>
                        <th scope="col" rowspan="2">Article No.</th>
                        <th scope="col" rowspan="2">HSN</th>
                        <th scope="col" rowspan="2">Size</th>
                        <th scope="col" rowspan="2">Mrp</th>
                        <th scope="col" rowspan="2">Qty</th>
                        <th scope="col" rowspan="2">Rate</th>
                        <th scope="col" rowspan="2">Taxable Value</th>
                        <th scope="col" colspan="2">CGST</th>
                        <th scope="col" colspan="2">SGST</th>
                        <th scope="col" colspan="2">IGST</th>
                        <th scope="col" rowspan="2">Total</th>
                    </tr>
                    <tr>
                        <th scope="col">(%)</th>
                        <th scope="col">Rs</th>
                        <th scope="col">(%)</th>
                        <th scope="col">Rs</th>
                        <th scope="col">(%)</th>
                        <th scope="col">Rs</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let obj of orderObj?.productArr; index as i">
                        <th scope="row">{{i+1}}</th>
                        <td>{{obj.productObj?.name}}</td>
                        <td>{{obj.productObj?.article}}</td>
                        <td>{{obj.productObj?.hsnCode}}</td>
                        <td>{{obj.size}}</td>
                        <td>{{obj.variantObj?.price}}</td>
                        <td>{{obj.qty}}</td>
                        <td>{{obj.variantObj?.wholesalePrice}}</td>
                        <td>{{obj.variantObj?.wholesalePrice * obj.qty }}</td>
                        <td>2.50</td>
                        <td>{{obj.variantObj?.wholesalePrice * obj.qty* 0.025}}</td>
                        <td>2.50</td>
                        <td>{{obj.variantObj?.wholesalePrice * obj.qty * 0.025}}</td>
                        <td>0</td>
                        <td>0</td>
                        <td>{{(obj.variantObj?.wholesalePrice * obj.qty * 0.025) + (obj.variantObj?.wholesalePrice *
                            obj.qty * 0.025) + (obj.variantObj?.wholesalePrice * obj.qty)}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="row">
                <div class="col"></div>
                <div class="col">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Total Amount Before</th>
                                <th scope="col">{{totalAmountBefore}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CGST </td>
                                <td>{{CGST}}</td>
                            </tr>
                            <tr>
                                <td>SGST</td>
                                <td>{{SGST}}</td>
                            </tr>
                            <tr>
                                <td>IGST</td>
                                <td>{{IGST}}</td>
                            </tr>
                            <tr>
                                <th scope="col">Tax Amount GST</th>
                                <th scope="col">{{taxAmount}}</th>
                            </tr>
                            <tr>
                                <td>Round off</td>
                                <td>{{roundOff}}</td>
                            </tr>
                            <tr>
                                <th scope="col">Total</th>
                                <th scope="col">{{Total}}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>