import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UrlService {
  // url = "http://localhost:4010";
  url="/api"
  // url = "http://ec2-65-1-55-43.ap-south-1.compute.amazonaws.com";

  // url = "http://192.168.0.158:4010"
  // url="http://65.1.55.43"

  PercentageOff = "Percentage Off";
  EffectivePrice = "Effective Price";
  constructor() {}
  generateFileUrl = (file: any) => {
    return `${this.url}/uploads/${file}`;
  };
}
