import { UrlService } from 'src/app/services/Url/url.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/area`

  async createArea(formData) {
    return this.http.post(`${this.serverUrl}/`, formData).toPromise()
  }
  async getAllAreas() {
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }
  async getAllAreasByCity(id) {
    return this.http.get(`${this.serverUrl}/getByCityId/${id}`).toPromise()
  }



  async toggleAreaStatus(id: any, obj: any) {
    return this.http.patch(`${this.serverUrl}/toggleArea/${id}`, obj).toPromise()
  }
  async updateArea(id: any, obj: any) {
    return this.http.patch(`${this.serverUrl}/setActiveVal/${id}`, obj).toPromise()
  }
  async removeArea(id: any) {
    return this.http.patch(`${this.serverUrl}/deleteById/${id}`, {}).toPromise()
  }

}
