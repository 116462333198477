import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private http: HttpClient, private ServerUrl: UrlService) { }
  url = `${this.ServerUrl.url}/question`

  addQuestion(formData) {
    return this.http.post(`${this.url}`, formData).toPromise()
  }

  getQuestions() {
    return this.http.get(`${this.url}`).toPromise()
  }

  getAnswers() {
    return this.http.get(`${this.url}/getUserAnswers`).toPromise()
  }


}
