import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../services/Product/product.service';

@Component({
  selector: 'app-generate-checked-print',
  templateUrl: './generate-checked-print.component.html',
  styleUrls: ['./generate-checked-print.component.scss']
})
export class GenerateCheckedPrintComponent implements OnInit {
  constructor(private product: ProductService, route: ActivatedRoute) {

  }
  barcodeContentArr: any = []


  ngOnInit(): void {
    this.getProductById()
  }


  async getProductById() {
    try {
      let barcodeArr = this.product.productArrforSelectedPrint;
      let finalbarcodeArr = barcodeArr;
      console.log(finalbarcodeArr,"final barcode arr")
      let tempArr=[]
      for (let el of finalbarcodeArr) {
        if (el.hasVariant) {
          let obj = {
            articleNo: el.productObj.article,
            price: el.variantObj.price,
            stockNo: el.variantObj.stockNo,
            name: el.variantObj.name,
            style: el.productObj.name,
            color: el.variantObj.variantList.find(el => el.name == "Color")?.variantName,
            size: el.variantObj.variantList.find(el => el.name == "Size")?.variantName,
            type: el.variantObj.variantList.find(el => el.name == "Type")?.variantName,
            // style: "sample",
            discountAmount:el.variantObj.discount*100,
            realizedValue:Math.round(el.variantObj.realizedValue),
            mfdMonth:el.variantObj.mfdMonth,
            qty: 1,
            marketedBy: el.productObj.createdLocation,
            address: "sample",
            email: 'sample@sample.com',
            phone: "1234567890"
          }
          tempArr.push(obj)
        }
        else {
          let obj = {
            articleNo: el.productObj.article,
            price: el.productObj.price,
            size: 40,
            stockNo: el.productObj.stockNo,
            color: 'White',
            style: "sample",
            qty: 1,
            discountAmount:el.productObj.discount*100,
            realizedValue:Math.round(el.productObj.realizedValue),
            mfdMonth:el.productObj.mfdMonth,
            marketedBy: el.productObj.createdLocation,
            address: "sample",
            email: 'sample@sample.com',
            phone: "1234567890"
          }
        }
      }
      this.barcodeContentArr=tempArr
    } catch (error) {
      console.error(error)
    }
  }


}
