import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/product`

  adminOrderUrl = `${this.url.url}/adminStoreOrder`
  adminReturnVendorOrderUrl = `${this.url.url}/AdminVendorReturnOrder`


  productArrforPrint = [];////this arr is for the case when the admin is selecting products from Product management
  productArrforSelectedPrint = [];////this arr is for the case when the admin is selecting products and their variants from stock logs 

  async addProduct(formData) {
    return this.http.post(`${this.serverUrl}`, formData).toPromise()
  }
  async updateById(id, formData) {
    return this.http.patch(`${this.serverUrl}/updateById/${id}`, formData).toPromise()
  }
  async getAllProducts() {
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }
  async getAllDefectiveProducts() {
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  async getByStockNo(storeNo) {
    return this.http.get(`${this.serverUrl}/getByStockNoAndStoreIdForAdmin/${storeNo}`).toPromise()
  }
  async getByStockNoInAdminOrder(stockNo, storeId) {
    return this.http.get(`${this.adminOrderUrl}/getByStockNoAndStoreIdForAdminOrders/${stockNo}/${storeId}`).toPromise()
  }
  async getProductById(id) {
    return this.http.get(`${this.serverUrl}/getById/${id}`).toPromise()
  }
  async getLastProduct() {
    return this.http.get(`${this.serverUrl}/getHighestStockNo`).toPromise()
  }
  async stockUpdateValuesByInvoiceNumber(billNo: any) {
    return this.http.get(`${this.serverUrl}/stockUpdateValuesByInvoiceNumber?invoiceNumber=${encodeURIComponent(billNo)}`).toPromise()
  }
  async stockUpdateValuesByDate(date: any, monthIndex: any, year: any, endDate: any, endMonthIndex: any, endYear: any) {
    return this.http.get(`${this.serverUrl}/stockUpdateValuesByDate/${year}/${monthIndex}/${date}/${endYear}/${endMonthIndex}/${endDate}`).toPromise()
  }


  async toggleProduct(id: any, obj: any) {
    return this.http.patch(`${this.serverUrl}/toggleProduct/${id}`, obj).toPromise()
  }

  async UploadCSV(obj: FormData) {
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
    // }
    console.log(`${this.serverUrl}/uploadXLSX`)
    return this.http.post(`${this.serverUrl}/uploadXLSX`, obj).toPromise()
  }
  async updateCSV(obj: FormData) {
    // const HttpUploadOptions = {
    //   headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
    // }
    console.log(`${this.serverUrl}/uploadXLSX`)
    return this.http.post(`${this.serverUrl}/updateXLSX`, obj).toPromise()
  }
  async removeProduct(id: any) {
    return this.http.patch(`${this.serverUrl}/removeProduct/${id}`, {}).toPromise()
  }


  async deliveryOrder(id: any) {
    return this.http.patch(`${this.adminOrderUrl}/deliver/${id}`, {}).toPromise()
  }

  async dispatchOrder(id: any) {
    return this.http.patch(`${this.adminOrderUrl}/dispatch/${id}`, {}).toPromise()
  }


  async newAdminOrder(obj: any) {
    return this.http.post(`${this.adminOrderUrl}/newOrder/`, obj).toPromise()
  }
  async newAdminReturnVendorOrder(obj: any) {
    return this.http.post(`${this.adminReturnVendorOrderUrl}/newOrder/`, obj).toPromise()
  }



  async deliveryReturnOrder(id: any) {
    return this.http.patch(`${this.adminReturnVendorOrderUrl}/deliver/${id}`, {}).toPromise()
  }

  async dispatchVendorReturnOrder(id: any) {
    return this.http.patch(`${this.adminReturnVendorOrderUrl}/dispatch/${id}`, {}).toPromise()
  }

  


  async getAllAdminOrders() {
    return this.http.get(`${this.adminOrderUrl}/getAllOrders/`).toPromise()
  }

  async getAllAdminVendorReturnOrders() {
    return this.http.get(`${this.adminReturnVendorOrderUrl}/getAllOrders/`).toPromise()
  }



  async getAllAuditOrders(storeId) {
    return this.http.get(`${this.serverUrl}/getAllAudits/${storeId}`).toPromise()
  }
  async getByAuditId(id) {
    return this.http.get(`${this.serverUrl}/getByAuditId/${id}`).toPromise()
  }
  

  
  async getProductsForReports() {
    return this.http.get(`${this.serverUrl}/getProductsForReport/`).toPromise()
  }


  async getProductReports(stockNo) {
    return this.http.get(`${this.serverUrl}/getProductReports/${stockNo}`).toPromise()
  }


  async getProductReportsStoreWise(storeId) {
    return this.http.get(`${this.serverUrl}/getStoreReports/${storeId}`).toPromise()
  }

  async getProductReportsStoreArrWise(obj) {
    return this.http.post(`${this.serverUrl}/getStoreReports`, obj).toPromise()
  }

  async getIncomingStockArr(obj) {
    return this.http.post(`${this.serverUrl}/getProductStockOpeningClosingReport`, obj).toPromise()
  }

  async getProductSalessStoreWiseAndDateWise(findObj) {
    return this.http.post(`${this.serverUrl}/getSalesStockFromStoreByStoreIdAndDate`, findObj).toPromise()
  }
  async getProductExchangeSalessStoreWiseAndDateWise(findObj) {
    return this.http.post(`${this.serverUrl}/getSalesStockExchangeFromStoreByStoreIdAndDate`, findObj).toPromise()
  }
  async getTotalProductSalessStoreWiseAndDateWise(findObj) {
    return this.http.post(`${this.serverUrl}/getTotalProductSalessStoreWiseAndDateWise`, findObj).toPromise()
  }

  // async getStoreWiseAuditQuantityWise(findObj) {
  //   return this.http.post(`${this.serverUrl}/getStoreWiseAuditQuantityWise`, findObj).toPromise()
  // }


  async getStoreWiseAuditQuantityWise(storeId, formData) {
    return this.http.post(`${this.serverUrl}/generateStockAudit/${storeId}`, formData).toPromise()
  }

  async createStoreAudit(storeId, formData) {

    return this.http.post(`${this.serverUrl}/createStoreAudit/${storeId}`, formData).toPromise()
  }





  async getTotalProductExchangeSalessStoreWiseAndDateWise(findObj) {
    return this.http.post(`${this.serverUrl}/getTotalProductExchangeSalessStoreWiseAndDateWise`, findObj).toPromise()
  }

  async getTotalGrossProfitStoreWiseAndDateWise(findObj) {
    return this.http.post(`${this.serverUrl}/getTotalGrossProfitStoreWiseAndDateWise`, findObj).toPromise()
  }

  async getItemWiseGrossProfitStoreCustomerWiseAndDateWise(findObj) {
    return this.http.post(`${this.serverUrl}/getItemWiseGrossProfitStoreCustomerWiseAndDateWise`, findObj).toPromise()
  }

  async getItemWiseGrossProfitStoreWiseAndDateWise(findObj) {
    return this.http.post(`${this.serverUrl}/getItemWiseGrossProfitStoreWiseAndDateWise`, findObj).toPromise()
  }

  async getTotalGrossProfitStoreCustomerWiseAndDateWise(findObj) {
    return this.http.post(`${this.serverUrl}/getTotalGrossProfitStoreCustomerWiseAndDateWise`, findObj).toPromise()
  }


  async getSalesStockExchangeFromAdminByStoreIdAndDate(findObj) {
    return this.http.post(`${this.serverUrl}/getSalesStockExchangeFromAdminByStoreIdAndDate`, findObj).toPromise()
  }

  async getFromAdminToStoreByStockNoAndDate(stockno, sday, smonth, syear, eday, emonth, eyear) {
    return this.http.get(`${this.serverUrl}/getSalesStockForStoresByStockNoAndDate/${stockno}/${syear}/${smonth}/${sday}/${eyear}/${emonth}/${eday}`).toPromise()
  }



  async getAdminStocks() {
    return this.http.get(`${this.serverUrl}/getStockInReportFormat`).toPromise()
  }




  async getProductsToPrint(arr) {
    return this.http.post(`${this.serverUrl}/getProductsToPrint`, { arr }).toPromise()
  }


  getCGstForProduct(productType: string | any, cost, sameState: Boolean = true): number {
    if (sameState) {
      if (!productType || productType.trim().toUpperCase() == "FOOTWEAR") {
        if (cost <= 999) {
          return 0.025
        }
        else {
          return 0.09
        }
      }
      else {
        if (cost <= 999) {
          return 0.025
        }
        else {
          return 0.06
        }
      }
    }
    else {
      return 0
    }
    return -1
  }

  getSGstForProduct(productType: string | any, cost, sameState: Boolean = true): number {
    if (sameState) {
      if (!productType || productType.trim().toUpperCase() == "FOOTWEAR") {
        if (cost <= 999) {
          return 0.025
        }
        else {
          return 0.09
        }
      }
      else {
        if (cost <= 999) {
          return 0.025
        }
        else {
          return 0.06
        }
      }
    }
    else {
      return 0
    }
  }

  getIGstForProduct(productType: string | any, cost, sameState: Boolean = true): number {
    if (!sameState) {
      if (!productType || productType.trim().toUpperCase() == "FOOTWEAR") {
        if (cost <= 999) {
          return 0.05
        }
        else {
          return 0.18
        }
      }
      else {
        if (cost <= 999) {
          return 0.05
        }
        else {
          return 0.12
        }
      }
    }
    else {
      return 0
    }
  }


}
