import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(private url:UrlService,private http:HttpClient) { }
  serverUrl=`${this.url.url}/review`

  
  getUnapprovedReviews(){
    return this.http.get(`${this.serverUrl}/getUnapproved`).toPromise()
  }

  getAllReviews(){
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  setReviewStatus(id: string,obj:any){
    return this.http.patch(`${this.serverUrl}/setStatus/${id}`,obj).toPromise()
  }


}
