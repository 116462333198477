import { UrlService } from 'src/app/services/Url/url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private url: UrlService, private http: HttpClient) { }
  serverUrl = `${this.url.url}/state`

  async createState(formData) {
    return this.http.post(`${this.serverUrl}/`, formData).toPromise()
  }
  async getAllStates() {
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }



  async toggleStateStatus(id: any, obj: any) {
    return this.http.patch(`${this.serverUrl}/toggleState/${id}`, obj).toPromise()
  }
  async updateState(id: any, obj: any) {
    return this.http.patch(`${this.serverUrl}/setActiveVal/${id}`, obj).toPromise()
  }
  async removeState(id: any) {
    return this.http.patch(`${this.serverUrl}/deleteById/${id}`, {}).toPromise()
  }


}
