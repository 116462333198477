<div class="container-fluid invoice">
  <div class="row border">
    <div class="col">
      <div style="border-bottom: solid 1px black">
        <div class="col-12 text-center">
          <b>AHEAD RETAIL PRIVATE LIMITED</b>
        </div>
        <div class="col-12 text-center">RZ-3A, SITAPURI, NEW DELHI, New Delhi, Delhi, 110045</div>
        <div class="col-12 text-center">New Delhi, Delhi, 110045</div>
        <div class="col-12 text-center">E-mail customersupport@jootelelo.com Phone</div>

        <div class="col-12 text-center">GSTIN : 07AAVCA9892L1ZI</div>
      </div>
      <div style="display: flex; justify-content: space-between" style="display: flex; flex-direction: row; justify-content: space-between; border-bottom: 1px solid black">
        <div></div>
        <div class="text-center" style="font-size: 30px; font-weight: bold">Debit Note</div>
        <div>Original</div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-6">Reverse Charge</div>
            <div class="col-6">: No</div>
          </div>
          <div class="row">
            <div class="col-6">Invoice Number</div>
            <div class="col-6">{{ orderObj?.invoiceNumber }}</div>
          </div>
          <div class="row">
            <div class="col-6">Invoice Date</div>
            <!-- <div class="col-6">{{ today | date: "dd/MM/yyyy" }}</div> -->
            <div class="col-6">19/08/2022</div>
          </div>
          <div class="row">
            <div class="col-6">PO Details</div>
            <div class="col-6"></div>
          </div>
        </div>
        <div class="col-6" style="border-left: 1px solid black">
          <div class="row">
            <div class="col-6">Transportation mode</div>
            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-6">Vehicle No</div>
            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-6">Date of Supply</div>
            <div class="col-6">
              <!-- {{ orderObj?.createdAt | date: "dd/MM/yyyy" }} -->
              <div class="col-6">19/08/2022</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="row" style="margin: 0px; padding: 0px">
            <div class="col-12" style="background-color: #dde087">Details of Receiver / Billed to:</div>
          </div>
          <div class="row">
            <div class="col-3">Name</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.name }}</div>
          </div>
          <div class="row">
            <div class="col-3">Address</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.address }}</div>
          </div>
          <div class="row">
            <div class="col-3">GSTIN</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.gstNumber }}</div>
          </div>
          <div class="row">
            <div class="col-3">Phone</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.phone }}</div>
          </div>
        </div>
        <div class="col-6" style="border-left: 1px solid black">
          <div class="row" style="margin: 0px; padding: 0px">
            <div class="col-12" style="background-color: #dde087">Details of Consignee / Shipped to:</div>
          </div>

          <div class="row">
            <div class="col-3">Name</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.name }}</div>
          </div>
          <div class="row">
            <div class="col-3">Address</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.address }}</div>
          </div>
          <div class="row">
            <div class="col-3">GSTIN</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.gstNumber }}</div>
          </div>
          <div class="row">
            <div class="col-3">Phone</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.phone }}</div>
          </div>
        </div>
      </div>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col" rowspan="2">#</th>
            <th scope="col" rowspan="2">Stock No</th>

            <th scope="col" rowspan="2">Item Description</th>
            <th scope="col" rowspan="2">Article No.</th>
            <th scope="col" rowspan="2">HSN</th>
            <th scope="col" rowspan="2">Size</th>
            <th scope="col" rowspan="2">Mrp</th>
            <th scope="col" rowspan="2">Qty</th>
            <th scope="col" rowspan="2">Rate</th>
            <th scope="col" rowspan="2">Taxable Value</th>
            <th scope="col" colspan="2">CGST</th>
            <th scope="col" colspan="2">SGST</th>
            <th scope="col" colspan="2">IGST</th>
            <!-- <th scope="col" rowspan="2">Taxable Value</th> -->
            <th scope="col" rowspan="2">Total</th>
          </tr>
          <tr>
            <th scope="col">(%)</th>
            <th scope="col">Rs</th>
            <th scope="col">(%)</th>
            <th scope="col">Rs</th>
            <th scope="col">(%)</th>
            <th scope="col">Rs</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let obj of orderObj?.productArr; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ obj.variantObj?.stockNo }}</td>
            <td>{{ obj.productObj?.name }}</td>

            <td>{{ obj.productObj?.article }}</td>
            <td>{{ obj.productObj?.hsnCode }}</td>
            <td>{{ obj.size }}</td>
            <td>{{ obj.variantObj?.price | number: "1.2-2" }}</td>
            <td>{{ obj.qty }}</td>
            <td>{{ obj.adminPurchasedPrice | number: "1.2-2" }}</td>
            <td>{{ obj.adminPurchasedPrice | number: "1.2-2" }}</td>
            <td>{{ obj.cgstPercentage * 100 }}</td>
            <td>{{ obj.cgst | number: "1.2-2" }}</td>
            <td>{{ obj.sgstPercentage * 100 }}</td>

            <td>{{ obj.sgst | number: "1.2-2" }}</td>
            <td>{{ obj.igstPercentage * 100 }}</td>

            <td>{{ obj.igst | number: "1.2-2" }}</td>
            <td>
              {{ (obj.adminPurchasedPrice + obj.igst + obj.sgst + obj.cgst) * obj.qty | number: "1.2-2" }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row">
        <div class="col"></div>
        <div class="col">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Total Amount Before</th>
                <th scope="col">{{ totalAmountBefore | currency: "INR" }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CGST</td>
                <td>{{ CGST | currency: "INR" }}</td>
              </tr>
              <tr>
                <td>SGST</td>
                <td>{{ SGST | currency: "INR" }}</td>
              </tr>
              <tr>
                <td>IGST</td>
                <td>{{ IGST | currency: "INR" }}</td>
              </tr>
              <tr>
                <th scope="col">Tax Amount GST</th>
                <th scope="col">{{ taxAmount | currency: "INR" }}</th>
              </tr>
              <tr>
                <td>Round off</td>
                <td>{{ roundOff | currency: "INR" }}</td>
              </tr>
              <tr>
                <td>Total Quantity</td>
                <td>{{ totalQuantity }}</td>
              </tr>
              <tr>
                <th scope="col">Total</th>
                <th scope="col">{{ Total | currency: "INR" }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid invoice">
  <div class="row border">
    <div class="col">
      <div style="border-bottom: solid 1px black">
        <div class="col-12 text-center">
          <b>AHEAD RETAIL PRIVATE LIMITED</b>
        </div>
        <div class="col-12 text-center">RZ-3A, SITAPURI, NEW DELHI, New Delhi, Delhi, 110045</div>
        <div class="col-12 text-center">New Delhi, Delhi, 110045</div>
        <div class="col-12 text-center">E-mail customersupport@jootelelo.com Phone</div>

        <div class="col-12 text-center">GSTIN : 07AAVCA9892L1ZI</div>
      </div>
      <div style="display: flex; justify-content: space-between" style="display: flex; flex-direction: row; justify-content: space-between; border-bottom: 1px solid black">
        <div></div>
        <div class="text-center" style="font-size: 30px; font-weight: bold">Debit Note</div>
        <div>Duplicate</div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-6">Reverse Charge</div>
            <div class="col-6">: No</div>
          </div>
          <div class="row">
            <div class="col-6">Invoice Number</div>
            <div class="col-6">{{ orderObj?.invoiceNumber }}</div>
          </div>
          <div class="row">
            <div class="col-6">Invoice Date</div>
            <!-- <div class="col-6">{{ today | date: "dd/MM/yyyy" }}</div> -->
            <div class="col-6">19/08/2022</div>
          </div>
          <div class="row">
            <div class="col-6">PO Details</div>
            <div class="col-6"></div>
          </div>
        </div>
        <div class="col-6" style="border-left: 1px solid black">
          <div class="row">
            <div class="col-6">Transportation mode</div>
            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-6">Vehicle No</div>
            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-6">Date of Supply</div>
            <!-- <div class="col-6">
              {{ orderObj?.createdAt | date: "dd/MM/yyyy" }}
              
            </div> -->
            <div class="col-6">19/08/2022</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="row" style="margin: 0px; padding: 0px">
            <div class="col-12" style="background-color: #dde087">Details of Receiver / Billed to:</div>
          </div>
          <div class="row">
            <div class="col-3">Name</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.name }}</div>
          </div>
          <div class="row">
            <div class="col-3">Address</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.address }}</div>
          </div>
          <div class="row">
            <div class="col-3">GSTIN</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.gstNumber }}</div>
          </div>
          <div class="row">
            <div class="col-3">Phone</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.phone }}</div>
          </div>
        </div>
        <div class="col-6" style="border-left: 1px solid black">
          <div class="row" style="margin: 0px; padding: 0px">
            <div class="col-12" style="background-color: #dde087">Details of Consignee / Shipped to:</div>
          </div>

          <div class="row">
            <div class="col-3">Name</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.name }}</div>
          </div>
          <div class="row">
            <div class="col-3">Address</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.address }}</div>
          </div>
          <div class="row">
            <div class="col-3">GSTIN</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.gstNumber }}</div>
          </div>
          <div class="row">
            <div class="col-3">Phone</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.phone }}</div>
          </div>
        </div>
      </div>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col" rowspan="2">#</th>
            <th scope="col" rowspan="2">Stock No</th>

            <th scope="col" rowspan="2">Item Description</th>
            <th scope="col" rowspan="2">Article No.</th>
            <th scope="col" rowspan="2">HSN</th>
            <th scope="col" rowspan="2">Size</th>
            <th scope="col" rowspan="2">Mrp</th>
            <th scope="col" rowspan="2">Qty</th>
            <th scope="col" rowspan="2">Rate</th>
            <th scope="col" rowspan="2">Taxable Value</th>
            <th scope="col" colspan="2">CGST</th>
            <th scope="col" colspan="2">SGST</th>
            <th scope="col" colspan="2">IGST</th>
            <!-- <th scope="col" rowspan="2">Taxable Value</th> -->
            <th scope="col" rowspan="2">Total</th>
          </tr>
          <tr>
            <th scope="col">(%)</th>
            <th scope="col">Rs</th>
            <th scope="col">(%)</th>
            <th scope="col">Rs</th>
            <th scope="col">(%)</th>
            <th scope="col">Rs</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let obj of orderObj?.productArr; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ obj.variantObj?.stockNo }}</td>
            <td>{{ obj.productObj?.name }}</td>

            <td>{{ obj.productObj?.article }}</td>
            <td>{{ obj.productObj?.hsnCode }}</td>
            <td>{{ obj.size }}</td>
            <td>{{ obj.variantObj?.price | number: "1.2-2" }}</td>
            <td>{{ obj.qty }}</td>
            <td>{{ obj.adminPurchasedPrice | number: "1.2-2" }}</td>
            <td>{{ obj.adminPurchasedPrice | number: "1.2-2" }}</td>
            <td>{{ obj.cgstPercentage * 100 }}</td>
            <td>{{ obj.cgst | number: "1.2-2" }}</td>
            <td>{{ obj.sgstPercentage * 100 }}</td>

            <td>{{ obj.sgst | number: "1.2-2" }}</td>
            <td>{{ obj.igstPercentage * 100 }}</td>

            <td>{{ obj.igst | number: "1.2-2" }}</td>
            <td>
              {{ (obj.adminPurchasedPrice + obj.igst + obj.sgst + obj.cgst) * obj.qty | number: "1.2-2" }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row">
        <div class="col"></div>
        <div class="col">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Total Amount Before</th>
                <th scope="col">{{ totalAmountBefore | currency: "INR" }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CGST</td>
                <td>{{ CGST | currency: "INR" }}</td>
              </tr>
              <tr>
                <td>SGST</td>
                <td>{{ SGST | currency: "INR" }}</td>
              </tr>
              <tr>
                <td>IGST</td>
                <td>{{ IGST | currency: "INR" }}</td>
              </tr>
              <tr>
                <th scope="col">Tax Amount GST</th>
                <th scope="col">{{ taxAmount | currency: "INR" }}</th>
              </tr>
              <tr>
                <td>Round off</td>
                <td>{{ roundOff | currency: "INR" }}</td>
              </tr>
              <tr>
                <td>Total Quantity</td>
                <td>{{ totalQuantity }}</td>
              </tr>
              <tr>
                <th scope="col">Total</th>
                <th scope="col">{{ Total | currency: "INR" }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid invoice">
  <div class="row border">
    <div class="col">
      <div style="border-bottom: solid 1px black">
        <div class="col-12 text-center">
          <b>AHEAD RETAIL PRIVATE LIMITED</b>
        </div>
        <div class="col-12 text-center">RZ-3A, SITAPURI, NEW DELHI, New Delhi, Delhi, 110045</div>
        <div class="col-12 text-center">New Delhi, Delhi, 110045</div>
        <div class="col-12 text-center">E-mail customersupport@jootelelo.com Phone</div>

        <div class="col-12 text-center">GSTIN : 07AAVCA9892L1ZI</div>
      </div>
      <div style="display: flex; justify-content: space-between" style="display: flex; flex-direction: row; justify-content: space-between; border-bottom: 1px solid black">
        <div></div>
        <div class="text-center" style="font-size: 30px; font-weight: bold">Debit Note</div>
        <div>Triplicate</div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-6">Reverse Charge</div>
            <div class="col-6">: No</div>
          </div>
          <div class="row">
            <div class="col-6">Invoice Number</div>
            <div class="col-6">{{ orderObj?.invoiceNumber }}</div>
          </div>
          <div class="row">
            <div class="col-6">Invoice Date</div>
            <div class="col-6">
              <!-- {{ orderObj?.invoiceDate | date: "dd/MM/yyyy" }} -->
              19/08/2022
            </div>
          </div>
          <div class="row">
            <div class="col-6">PO Details</div>
            <div class="col-6"></div>
          </div>
        </div>
        <div class="col-6" style="border-left: 1px solid black">
          <div class="row">
            <div class="col-6">Transportation mode</div>
            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-6">Vehicle No</div>
            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-6">Date of Supply</div>
            <!-- <div class="col-6">
              {{ orderObj?.invoiceDate | date: "dd/MM/yyyy" }}
            </div> -->
            <div class="col-6">19/08/2022</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="row" style="margin: 0px; padding: 0px">
            <div class="col-12" style="background-color: #dde087">Details of Receiver / Billed to:</div>
          </div>
          <div class="row">
            <div class="col-3">Name</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.name }}</div>
          </div>
          <div class="row">
            <div class="col-3">Address</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.address }}</div>
          </div>
          <div class="row">
            <div class="col-3">GSTIN</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.gstNumber }}</div>
          </div>
          <div class="row">
            <div class="col-3">Phone</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.phone }}</div>
          </div>
        </div>
        <div class="col-6" style="border-left: 1px solid black">
          <div class="row" style="margin: 0px; padding: 0px">
            <div class="col-12" style="background-color: #dde087">Details of Consignee / Shipped to:</div>
          </div>

          <div class="row">
            <div class="col-3">Name</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.name }}</div>
          </div>
          <div class="row">
            <div class="col-3">Address</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.address }}</div>
          </div>
          <div class="row">
            <div class="col-3">GSTIN</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.gstNumber }}</div>
          </div>
          <div class="row">
            <div class="col-3">Phone</div>
            <div class="col-9">{{ orderObj?.VendorDetails?.phone }}</div>
          </div>
        </div>
      </div>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col" rowspan="2">#</th>
            <th scope="col" rowspan="2">Stock No</th>

            <th scope="col" rowspan="2">Item Description</th>
            <th scope="col" rowspan="2">Article No.</th>
            <th scope="col" rowspan="2">HSN</th>
            <th scope="col" rowspan="2">Size</th>
            <th scope="col" rowspan="2">Mrp</th>
            <th scope="col" rowspan="2">Qty</th>
            <th scope="col" rowspan="2">Rate</th>
            <th scope="col" rowspan="2">Taxable Value</th>
            <th scope="col" colspan="2">CGST</th>
            <th scope="col" colspan="2">SGST</th>
            <th scope="col" colspan="2">IGST</th>
            <!-- <th scope="col" rowspan="2">Taxable Value</th> -->
            <th scope="col" rowspan="2">Total</th>
          </tr>
          <tr>
            <th scope="col">(%)</th>
            <th scope="col">Rs</th>
            <th scope="col">(%)</th>
            <th scope="col">Rs</th>
            <th scope="col">(%)</th>
            <th scope="col">Rs</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let obj of orderObj?.productArr; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ obj.variantObj?.stockNo }}</td>
            <td>{{ obj.productObj?.name }}</td>

            <td>{{ obj.productObj?.article }}</td>
            <td>{{ obj.productObj?.hsnCode }}</td>
            <td>{{ obj.size }}</td>
            <td>{{ obj.variantObj?.price | number: "1.2-2" }}</td>
            <td>{{ obj.qty }}</td>
            <td>{{ obj.adminPurchasedPrice | number: "1.2-2" }}</td>
            <td>{{ obj.adminPurchasedPrice | number: "1.2-2" }}</td>
            <td>{{ obj.cgstPercentage * 100 }}</td>
            <td>{{ obj.cgst | number: "1.2-2" }}</td>
            <td>{{ obj.sgstPercentage * 100 }}</td>

            <td>{{ obj.sgst | number: "1.2-2" }}</td>
            <td>{{ obj.igstPercentage * 100 }}</td>

            <td>{{ obj.igst | number: "1.2-2" }}</td>
            <td>
              {{ (obj.adminPurchasedPrice + obj.igst + obj.sgst + obj.cgst) * obj.qty | number: "1.2-2" }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row">
        <div class="col"></div>
        <div class="col">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Total Amount Before</th>
                <th scope="col">{{ totalAmountBefore | currency: "INR" }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CGST</td>
                <td>{{ CGST | currency: "INR" }}</td>
              </tr>
              <tr>
                <td>SGST</td>
                <td>{{ SGST | currency: "INR" }}</td>
              </tr>
              <tr>
                <td>IGST</td>
                <td>{{ IGST | currency: "INR" }}</td>
              </tr>
              <tr>
                <th scope="col">Tax Amount GST</th>
                <th scope="col">{{ taxAmount | currency: "INR" }}</th>
              </tr>
              <tr>
                <td>Round off</td>
                <td>{{ roundOff | currency: "INR" }}</td>
              </tr>
              <tr>
                <td>Total Quantity</td>
                <td>{{ totalQuantity }}</td>
              </tr>
              <tr>
                <th scope="col">Total</th>
                <th scope="col">{{ Total | currency: "INR" }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
