import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '../Url/url.service';

@Injectable({
  providedIn: 'root'
})
export class AttributeSetService {

  constructor(private url:UrlService,private http:HttpClient) { }
  serverUrl=`${this.url.url}/AttributeSet`

  async addAttributeSet(formData){
    return this.http.post(`${this.serverUrl}`,formData).toPromise()
  }
  async getAllAttributeSet(){
    return this.http.get(`${this.serverUrl}/`).toPromise()
  }

  

  async toggleAttributeSet(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/toggleAttributeSet/${id}`,obj).toPromise()
  }
  async removeAttributeSet(id:any,obj:any){
    return this.http.patch(`${this.serverUrl}/removeAttributeSet/${id}`,obj).toPromise()
  }

}
