import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariantService } from 'src/app/services/Variant/variant.service';

@Component({
  selector: 'app-add-variant-color',
  templateUrl: './add-variant-color.component.html',
  styleUrls: ['./add-variant-color.component.scss']
})
export class AddVariantColorComponent implements OnInit {
  variantId = "";
  variantObj: any
  constructor(private route: ActivatedRoute, private variant: VariantService) {
    this.variantId = route.snapshot.params['id']
  }

  ngOnInit(): void {
    this.getVariant()
  }


  async getVariant() {
    try {
      const res: any = await this.variant.getSpecificVariant(this.variantId);
      console.log(res)
      if (res.success) {
        this.variantObj = res.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  async handleVariantColorSelection(id, event) {
    let variantIndex = this.variantObj.variantArr.findIndex(el => el._id == id);
    if (variantIndex != -1) {
      this.variantObj.variantArr[variantIndex].colorHex = event.target.value
    }
  }
  async handleSubmit() {
    try {

      let arr = this.variantObj.variantArr.filter(el => el.colorHex && el.colorHex != "")
      let res: any = await this.variant.updateVariantColor(this.variantId, { variantArr: arr });
      if (res.success) {
        alert(res.message)
      }
    }
    catch (error) {
      console.error(error)
      alert(error.message)
    }
  }
}


