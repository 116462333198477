import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{
			title: 'Location Management', icon: 'home', type: 'sub', badgeType: 'success', active: false, children: [
				{ path: '/location-management/state', title: 'State management', type: 'link' },
				{ path: '/location-management/city', title: 'City management', type: 'link' },
				{ path: '/location-management/area', title: 'Area management', type: 'link' },
			]
		},
		{
			title: 'Product Management', icon: 'home', type: 'sub', badgeType: 'success', active: false, children: [
				{ path: '/brand-management', title: 'Brand-Management', type: 'link' },
				{ path: '/category-management', title: 'Category-Management', type: 'link' },
				{ path: '/tag-management', title: 'Tags', type: 'link' },
				{ path: '/variant-management', title: 'Variant', type: 'link' },
				{ path: '/product-management', title: 'Product-Management', type: 'link' },
				{ path: '/product-management/area-scheme-price-management', title: 'Area scheme Management', type: 'link' },
				{ path: '/product-management/area-price-management', title: 'Area Price Management', type: 'link' },
				{ path: '/product-management/stockLogs', title: 'Stock Logs', type: 'link' },

			]
		},
		
		{
			title: 'Media Management', icon: 'home', type: 'sub', badgeType: 'success', active: false, children: [
				{ path: '/media-management', title: 'Media', type: 'link' },
				{ path: '/banner-management', title: 'banner-Management', type: 'link' },
			]
		},
		{
			title: 'Coupon Management', icon: 'home', type: 'sub', badgeType: 'success', active: false, children: [
				{ path: '/coupon-management', title: 'Coupon', type: 'link' },
				// { path: '/banner-management', title: 'banner-Management', type: 'link' },
			]
		},
		{
			title: 'Store Management', icon: 'home', type: 'sub', badgeType: 'success', active: false, children: [
				{ path: '/store-management/store-create', title: 'Stores Create', type: 'link' },
				{ path: '/store-management/store-View', title: 'Stores Stock', type: 'link' },
				{ path: '/store-management/defective-stock', title: 'Defective Stock', type: 'link' },
				{ path: '/store-management/AdminVendorReturnOrder', title: 'New AdminVendorReturnOrder', type: 'link' },
				{ path: '/store-management/AdminVendorReturnAllOrders', title: 'AdminVendorReturnAllOrders', type: 'link' },
				{ path: '/store-management/BuyonegetoneAdd', title: 'BuyonegetoneAdd', type: 'link' },
				{ path: '/store-management/BuyonegetoneAll', title: 'BuyonegetoneAll', type: 'link' },




			]
		},
		{
			title: 'Report', icon: 'home', type: 'sub', badgeType: 'success', active: false, children: [
				{ path: '/report', title: 'Reports', type: 'link' },
				{ path: '/report/globalStocks', title: 'Admin Stocks', type: 'link' },
			]
		},
	];

	MEGAMENUITEMS: Menu[] = [
	];

	LEVELMENUITEMS: Menu[] = [
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
