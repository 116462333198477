import { GenerateCheckedPrintComponent } from "./generate-checked-print/generate-checked-print.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";

import { AdminGuard } from "./shared/guard/admin.guard";
import { AuthloginGuard } from "./guards/authlogin.guard";
import { AuthlogoutGuard } from "./guards/authlogout.guard";
import { GeneratePrintComponent } from "./generate-print/generate-print.component";
import { AdminStoreOrderInvoiceComponent } from "./admin-store-order-invoice/admin-store-order-invoice.component";
import { PrintStoreInvoiceComponent } from "./print-store-invoice/print-store-invoice.component";
import { AdminStoreOrderTriplicateInvoiceComponent } from "./admin-store-order-triplicate-invoice/admin-store-order-triplicate-invoice.component";
import { AdminStoreOrderDuplicateInvoiceComponent } from "./admin-store-order-duplicate-invoice/admin-store-order-duplicate-invoice.component";
import { AdminVendorReturnOrderInvoiceComponent } from "./components/store-management/admin-vendor-return-order-invoice/admin-vendor-return-order-invoice.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "auth/login",
    component: LoginComponent,
    canActivate: [AuthlogoutGuard],
  },
  {
    path: "generatePrint",
    component: GeneratePrintComponent,
  },
  {
    path: "checkedPrint",
    component: GenerateCheckedPrintComponent,
  },
  {
    path: "OrderInvoice/:id/:original",
    component: AdminStoreOrderInvoiceComponent,
  },
  { path: "AdminVendorReturnAllOrdersInvoice/:id", component: AdminVendorReturnOrderInvoiceComponent },

  {
    path: "StoreOrderInvoice/:id/:original",
    component: PrintStoreInvoiceComponent,
  },
  {
    path: "",
    component: ContentComponent,
    canActivate: [AuthloginGuard],
    children: content,
  },
  {
    path: "",
    component: FullComponent,
    // canActivate: [AdminGuard],
    children: full,
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
        relativeLinkResolution: "legacy",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
